import axios, { AxiosError, type AxiosResponse } from "axios";
import { API_URL } from "../config";

const api = axios.create({
  baseURL: API_URL,
});

export interface AmoRequest {
  name: string;
  message?: string;
  origin?: string;
  referer?: string;
  tag_ids?: Array<number>;
  contact: {
    name: string;
    email?: string;
    phone?: string;
  };
  utm_data?: {
    utm_source?: string;
    utm_medium?: string;
    utm_campaign?: string;
    utm_content?: string;
    utm_term?: string;
  };
}

export interface CitadelApiError {
  type: string;
  errors: [
    {
      code: string;
      detail: string;
      attr: string;
    }
  ];
}

export function sendRequestToAMO(
  data: AmoRequest
): Promise<AxiosResponse<undefined, CitadelApiError>> {
  const referer = window.document.referrer || undefined;
  const url = new URL(window.location.href);
  const params = url.searchParams;

  const utm_data: AmoRequest["utm_data"] = {
    utm_source: params.get("utm_source") || undefined,
    utm_medium: params.get("utm_medium") || undefined,
    utm_campaign: params.get("utm_campaign") || undefined,
    utm_content: params.get("utm_content") || undefined,
    utm_term: params.get("utm_term") || undefined,
  };

  const hasUtmValue = Object.values(utm_data).some(
    (value) => value !== undefined && value !== ""
  );

  const body: AmoRequest = {
    ...data,
    referer,
    origin: "Конференция по нутрициологии",
    utm_data: hasUtmValue ? utm_data : undefined,
  };

  return api.post("/api/v1/amo/create/leads/", body);
}
