import { createStyles } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import React from "react";

// ----------------------------------------------------------------------

const useStyles = createStyles((theme) => ({
  image: {
    objectFit: "contain",
    verticalAlign: "middle",
    height: 52,
  },

  imageSmall: {
    objectFit: "contain",
    verticalAlign: "middle",
    width: 66,
    height: 52,
  },
}));

type Props = {
  className?: string;
};

export function Logo({ className = "" }: Props): React.JSX.Element {
  const { classes } = useStyles();
  const isMobile = useMediaQuery(`(max-width: 955px)`);

  return (
    <div className={className}>
      {isMobile && (
        <img
          className={classes.imageSmall}
          src="assets/logo.png"
          alt="Логотип Body Coach"
        />
      )}
      {!isMobile && (
        <img
          className={classes.image}
          src="assets/logo-horizontal.png"
          alt="Логотип Body Coach"
        />
      )}
    </div>
  );
}
