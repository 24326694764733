import React, { useEffect, useMemo, useState } from "react"
import {
  Alert,
  Anchor,
  Button,
  Container,
  createStyles,
  Grid,
  Input,
  Modal,
  Stack,
  Text,
  TextInput,
} from "@mantine/core"
import { observer } from "mobx-react-lite"
import { FormModalStore } from "../../app/FormModalStore"
import { useForm, yupResolver } from "@mantine/form"
import {
  AmoRequest,
  CitadelApiError,
  sendRequestToAMO,
} from "../../shared/api/amo"
import { AxiosError } from "axios"
import { IconAlertCircle, IconCircleCheck } from "@tabler/icons-react"
import { IMaskInput } from "react-imask"
import { useMediaQuery } from "@mantine/hooks"
import { primaryColor, secondaryColor } from "../../constants"
import { wrapUrlWithCurrentQuery } from "shared/utils/url"
import { CountrySelector, formSchema, getPhoneMaskData } from "../country-selector"
import { countries, CountryPhone } from "../country-selector/countries"

const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent)

const useStyles = createStyles((theme) => ({
  modalContent: {
    width: "100%",
    maxWidth: 640,

    [theme.fn.smallerThan(1024)]: {
      maxWidth: 480,
    },
  },

  modalHeader: {
    padding: "32px 32px 24px",

    [theme.fn.smallerThan(425)]: {
      padding: 16,
    },
  },

  modalBody: {
    padding: 32,

    [theme.fn.smallerThan(425)]: {
      padding: 16,
    },
  },

  modalTitle: {
    fontWeight: 900,
    fontSize: 24,

    [theme.fn.smallerThan(425)]: {
      fontWeight: 700,
      fontSize: 20,
    },
  },

  checkbox: {
    ".mantine-InputWrapper-error": {
      fontSize: 16,

      [theme.fn.smallerThan(425)]: {
        fontSize: 14,
      },
    },
  },
}))

export const FormModal = observer(function FormModal(): React.JSX.Element {
  const { classes } = useStyles()
  const matched = useMediaQuery(`(max-width: 768px)`)
  const is425 = useMediaQuery(`(max-width: 425px)`)

  const [ isLoading, setIsLoading ] = useState<boolean>(false)
  const [ isSuccess, setIsSuccess ] = useState<boolean>(false)
  const [ error, setError ] = useState<string | null>(null)

  const form = useForm({
    validateInputOnChange: true,
    validate: yupResolver(formSchema),
    initialValues: {
      name: "",
      phone: "",
      countryPhone: countries[0],
    },
  })

  const [ phoneMask, phonePlaceholder ] = useMemo(() => {
    const countryPhone = form.values.countryPhone
    return getPhoneMaskData(countryPhone)
  }, [ form.values.countryPhone.code ])

  const onSubmit = (values: typeof form.values): void => {
    setIsLoading(false)
    setIsSuccess(false)
    setError(null)

    const body: AmoRequest = {
      contact: {
        name: values.name,
        phone: values.phone.replace(/[\s()-]/g, ""),
      },
      name: FormModalStore.data.target ?? "",
    }

    if (FormModalStore.data.tag_ids !== undefined) {
      body.tag_ids = FormModalStore.data.tag_ids
    }
    void sendRequestToAMO(body)
      .then(() => {
        setIsSuccess(true)
      })
      .catch((err: AxiosError<CitadelApiError>) => {
        if (err.response) {
          setError(err.response.data.errors[0].detail)
        }
      })
      .finally(() => {
        setIsLoading(false)
        const newUrl = wrapUrlWithCurrentQuery(
          "https://r.bothelp.io/tg?domain=doc_nutr_conf_25_bot&start=c1742392036432-ds",
        )
        console.log("Send to the bot. URL: ", newUrl)

        if (isIOS) {
          window.location.href = newUrl
        } else {
          window.open(newUrl, "_blank")
        }
      })
  }

  useEffect(() => {
    if (!FormModalStore.isOpened) {
      form.reset()
      setIsLoading(false)
      setIsSuccess(false)
      setError(null)
    }
  }, [ FormModalStore.isOpened ])

  const close = () => FormModalStore.setIsOpened(false)

  return (
    <Modal.Root
      zIndex={2000}
      size="auto"
      centered
      opened={FormModalStore.isOpened}
      onClose={close}
    >
      <Modal.Overlay/>
      <Modal.Content radius="lg" className={classes.modalContent}>
        <Modal.Header className={classes.modalHeader}>
          <Modal.Title className={classes.modalTitle}>
            Оставить заявку
          </Modal.Title>
          <Modal.CloseButton variant="light" size="xl" radius="lg"/>
        </Modal.Header>
        <Modal.Body className={classes.modalBody}>
          {isSuccess ? (
            <Container p={0} size={560}>
              <Alert
                styles={{ title: { fontSize: 18 }, message: { fontSize: 16 } }}
                icon={<IconCircleCheck/>}
                title="Заявка успешно отправлена!"
                color={secondaryColor}
                radius="lg"
              >
                {FormModalStore.data.tag_ids === undefined && (
                  <Text>
                    В ближайшее время с Вами свяжется наш сотрудник и ответит на
                    все Ваши вопросы.
                  </Text>
                )}
                {FormModalStore.data.tag_ids !== undefined && (
                  <>
                    <Text>
                      Сейчас вы будете автоматически перенаправлены в бота
                      конференции, куда будут приходить уведомления.
                    </Text>
                    <Text mt="xs">
                      Если этого не произошло -{" "}
                      <Anchor
                        target="_blank"
                        href={wrapUrlWithCurrentQuery(
                          "https://r.bothelp.io/tg?domain=doc_nutr_conf_25_bot&start=c1742392036432-ds",
                        )}
                      >
                        нажмите на эту кнопку
                      </Anchor>{" "}
                      , чтобы перейти в телеграм-бота конференции вручную.
                    </Text>
                  </>
                )}
              </Alert>
            </Container>
          ) : (
            <Container p={0} size={560}>
              <form onSubmit={form.onSubmit(onSubmit)}>
                <Stack spacing={is425 ? "md" : "xl"}>
                  <Text size="sm" c="dimmed">
                    Мы свяжемся с вами в ближайшее время.
                  </Text>
                  <TextInput
                    disabled={isLoading}
                    placeholder="Имя"
                    radius="lg"
                    size={matched ? "md" : "lg"}
                    {...form.getInputProps("name")}
                  />

                  <Grid gutter={is425 ? "md" : "lg"}>
                    <Grid.Col xs={12} sm={5} lg={6}>
                      <CountrySelector
                        disabled={isLoading}
                        value={form.values.countryPhone}
                        onChange={(countryPhone: CountryPhone) => {
                          form.setFieldValue("countryPhone", countryPhone)
                          Promise.resolve()
                            .then(() => form.setFieldValue("phone", ""))
                            .then(() => form.clearFieldError("phone"))
                        }}
                      />
                    </Grid.Col>
                    <Grid.Col span="auto">
                      <Input.Wrapper
                        id="participant-form-phone"
                        error={form.errors["phone"]}
                        size={matched ? "md" : "lg"}
                      >
                        <Input
                          disabled={isLoading}
                          component={IMaskInput}
                          mask={phoneMask}
                          id="participant-form-phone"
                          placeholder={phonePlaceholder}
                          radius="lg"
                          size={matched ? "md" : "lg"}
                          {...form.getInputProps("phone")}
                        />
                      </Input.Wrapper>
                    </Grid.Col>
                  </Grid>

                  <Button
                    loading={isLoading}
                    type="submit"
                    fullWidth
                    variant="gradient"
                    gradient={{
                      from: `${primaryColor}.9`,
                      to: `${secondaryColor}.8`,
                    }}
                    radius="lg"
                    size={matched ? "md" : "lg"}
                    onClick={() => {
                      // @ts-ignore
                      _tmr.push({
                        type: "reachGoal",
                        id: 3499061,
                        goal: "leadbuy",
                      })
                    }}
                  >
                    Отправить
                  </Button>

                  {error && (
                    <Alert
                      icon={<IconAlertCircle/>}
                      title="Ошибка отправки данных"
                      color="red"
                      radius="lg"
                    >
                      {error}
                    </Alert>
                  )}
                </Stack>
              </form>
            </Container>
          )}
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  )
})
