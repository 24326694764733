import {
  Button,
  Text,
  Paper,
  createStyles,
  Stack,
  Group,
  Center,
  Divider,
  Flex,
} from "@mantine/core";
import { IconCheck, IconFlame } from "@tabler/icons-react";
import { FormModalStore } from "../../app/FormModalStore";
import { primaryColor, secondaryColor } from "../../constants";
import { wrapUrlWithCurrentQuery } from "shared/utils/url";

const useStyles = createStyles((theme) => ({
  wrapper: {
    width: "100%",
    maxWidth: 375,
    position: "relative",
    background: "transparent",
  },

  innerBox: {
    overflow: "hidden",
    borderRadius: theme.radius.xl,
    position: "relative",
    background: theme.fn.linearGradient(
      45,
      theme.colors[primaryColor][7],
      theme.colors[secondaryColor][7]
    ),
    paddingBottom: 30,
    margin: "0 auto",
    zIndex: 0,
    border: `2px solid ${theme.colors[primaryColor][5]}`,
    boxShadow: "rgba(0, 0, 0, 0.45) 0px 3px 15px",

    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundImage: "url(/assets/pattern.png)",
      backgroundSize: "cover",
      backgroundPosition: "center",
      opacity: 0.2,
      zIndex: -1,
    },
  },

  iconBox: {
    position: "relative",
    padding: "50px 30px 0",
    textAlign: "center",
    color: "white",
    filter: "drop-shadow(0px 5px 10px #222)",
  },

  textContainer: {
    marginTop: 32,

    [theme.fn.smallerThan("sm")]: {
      marginTop: 24,
    },
  },

  textGroup: {
    gap: theme.spacing.md,

    svg: {
      minWidth: 24,
      minHeight: 24,
    },

    [theme.fn.smallerThan("sm")]: {
      gap: theme.spacing.xs,
    },
  },

  text: {
    fontSize: theme.fontSizes.md,

    [theme.fn.smallerThan("sm")]: {
      fontSize: theme.fontSizes.sm,
    },
  },

  btn: {
    backgroundColor: theme.white,
    color: theme.black,

    "&:hover": {
      backgroundColor: theme.white,
      opacity: 0.9,
    },
  },

  oldPrice: {
    display: "inline-block",
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    left: "100%",
    opacity: 0.85,

    "&::after": {
      content: "''",
      position: "absolute",
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      width: "100%",
      height: "100%",
      backgroundImage:
        "url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiPgogICAgPGxpbmUgeDE9IjAiIHkxPSIxMDAlIiB4Mj0iMTAwJSIgeTI9IjAiIHN0cm9rZT0iIzAwMCIgc3Ryb2tlLXdpZHRoPSIzIi8+Cjwvc3ZnPg==')",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      opacity: 0.7,
    },
  },
}));

function getPrice(): [number, number | null] {
  const currentDate = new Date();
  const targetFirstDate = new Date("2025-03-31T00:00:00.000+03:00");
  const targetSecondDate = new Date("2025-04-14T00:00:00.000+03:00");
  // const targetThirdDate = new Date("2024-11-25T00:00:00.000+03:00")

  if (currentDate < targetFirstDate) {
    return [4990, 6990];
  }

  if (currentDate < targetSecondDate) {
    return [6990, 8990];
  }

  // if (currentDate < targetThirdDate) {
  //   return [ 5790, 7000 ]
  // }

  return [8990, null];
}

export function PricingPlanSection() {
  const { classes } = useStyles();
  const [price, prev] = getPrice();

  const onClick = () => {
    FormModalStore.setData({
      tag_ids: [1232125, 1232143],
      target: `Конференция по нутрициологии. Выбран ПРОДВИНУТЫЙ тариф. Цена: ${price}`,
    });
  };

  return (
    <Paper className={classes.wrapper}>
      <div className={classes.innerBox}>
        <div className={classes.iconBox}>
          <IconFlame size={70} stroke={1.5} />
        </div>

        <Text color="white" fw={700} align="center" size={22} mb={32}>
          Продвинутый
        </Text>

        <Center>
          <Group align="center">
            <Text
              align="center"
              size={32}
              fw={900}
              color="white"
              sx={{ position: "relative" }}
            >
              {price}&nbsp;&#8381;
              {prev && (
                <Text
                  className={classes.oldPrice}
                  ml={12}
                  color="dark"
                  fw={900}
                  size={22}
                  component="span"
                >
                  &nbsp;{prev}&nbsp;&#8381;&nbsp;
                </Text>
              )}
            </Text>
          </Group>
        </Center>

        <Center className={classes.textContainer}>
          <Stack spacing={0} px={36}>
            <Flex className={classes.textGroup}>
              <IconCheck color="white" />
              <Text c="white" className={classes.text}>
                Онлайн участие в конференции
              </Text>
            </Flex>
            <Divider my="sm" variant="dashed" />
            <Flex className={classes.textGroup}>
              <IconCheck color="white" />
              <Text c="white" className={classes.text}>
                Сертификат участника
                <br />
                &#171;Доказательная нутрициология 2025&#187;
              </Text>
            </Flex>
            <Divider my="sm" variant="dashed" />
            <Flex className={classes.textGroup}>
              <IconCheck color="white" />
              <Text c="white" className={classes.text}>
                Записи выступлений
                <br />
                &#171;Доказательная нутрициология 2025&#187;
              </Text>
            </Flex>
          </Stack>
        </Center>

        <Group className={classes.textContainer} position="center">
          <Button
            className={classes.btn}
            radius="lg"
            size="md"
            onClick={onClick}
          >
            Оставить заявку
          </Button>
        </Group>
      </div>
    </Paper>
  );
}
