import { useCallback, useState } from "react"
import { IconChevronDown } from "@tabler/icons-react"
import { createStyles, Flex, Menu, ScrollArea, Text, UnstyledButton } from "@mantine/core"
import { countries, CountryPhone } from "./countries"

const useStyles = createStyles((theme) => ({
  control: {
    width: "100%",
    height: 50,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: `0 ${theme.spacing.md}`,
    borderRadius: theme.radius.lg,
    border: `1px solid ${theme.colors.gray[4]}`,
    transition: "background-color 150ms ease",
    backgroundColor: `white`,

    [theme.fn.smallerThan(768)]: {
      height: 42,
    },

    "&[data-expanded]": {
      backgroundColor: `${theme.colors.gray[0]}`,
    },

    "&[disabled]": {
      opacity: 0.5,
      backgroundColor: `${theme.colors.gray[1]}`,
    },

    "&:hover": {
      backgroundColor: `${theme.colors.gray[0]}`,
    },
  },

  label: {
    fontWeight: 500,
    fontSize: theme.fontSizes.sm,
  },

  icon: {
    marginLeft: theme.spacing.xs,
    transition: "transform 150ms ease",
    transform: "rotate(0deg)",

    "[data-expanded] &": {
      transform: "rotate(180deg)",
    },
  },

  flagImg: {
    width: "100%",
    height: "100%",
    maxWidth: "unset",
    objectFit: "cover",
  },

  flagRoot: {
    width: 26,
    height: 20,
    flexShrink: 0,
    overflow: "hidden",
    borderRadius: "5px",
    alignItems: "center",
    display: "inline-flex",
    justifyContent: "center",
    backgroundColor: "white",
  },

  codePrefix: {
    whiteSpace: "nowrap",
  },

  dropdown: {
    borderRadius: theme.radius.lg,
  },

  active: {
    backgroundColor: theme.colors.gray[2],
  },
}))

function Flag({ code }: { code: string }) {
  const { classes } = useStyles()

  return (
    <span className={classes.flagRoot}>
      <img
        className={classes.flagImg}
        loading="lazy"
        alt={code}
        src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${code.toUpperCase()}.svg`}
      />
    </span>
  )
}

type Props = {
  onChange: (countryPhone: CountryPhone) => void
  value: CountryPhone
  disabled?: boolean
}

export function CountrySelector(props: Props) {
  const { classes, cx } = useStyles()
  const [ opened, setOpened ] = useState(false)

  const onChange = useCallback((countryPhone: CountryPhone) => {
    props.onChange(countryPhone)
  }, [ props.onChange, props.value.code ])

  const items = countries.map((item) => (
    <Menu.Item
      icon={<Flag code={item.code}/>}
      onClick={() => onChange(item)}
      key={item.label}
      className={cx({ [classes.active]: item.code === props.value.code })}
    >
      <Flex align="center" gap="xs">
        <Text lineClamp={2} lh={1.2}>
          {`+${item.phone} - ${item.label}`}
        </Text>
      </Flex>
    </Menu.Item>
  ))

  return (
    <Menu
      disabled={props.disabled}
      zIndex={10000}
      onOpen={() => setOpened(true)}
      onClose={() => setOpened(false)}
      radius="md"
      width="target"
      withinPortal
      position="bottom"
    >
      <Menu.Target>
        <UnstyledButton
          disabled={props.disabled}
          className={classes.control}
          data-expanded={opened || undefined}
        >
          <Flex align="center" gap="xs">
            <Flag code={props.value.code}/>
            <Text lh={1.2} lineClamp={2} className={classes.label}>{props.value.label}</Text>
          </Flex>
          <IconChevronDown size={16} className={classes.icon} stroke={1.5}/>
        </UnstyledButton>
      </Menu.Target>
      <Menu.Dropdown className={classes.dropdown}>
        <ScrollArea h={250}>
          {items}
        </ScrollArea>
      </Menu.Dropdown>
    </Menu>
  )
}