import { Anchor, Text } from "@mantine/core"

export const menuItems = [
  { href: "main", label: "Главная" },
  { href: "schedule", label: "Расписание" },
  { href: "speakers", label: "Спикеры" },
  { href: "askQuestion", label: "Задать вопрос" },
]

export const room1 = [
  {
    time: "11:00 - 12:00",
    speakerName: "Александра Ситнова",
    description: "Нутрициолог",
    value: "sitnova",
    title: "\"Каша\" лжи: Разбираем мифы о детском питании",
    small: "assets/speakers/sitnova/sitnova-small.jpg",
    cover: "assets/speakers/sitnova/sitnova.jpg",
    texts: [
      "Инфлюенсер в сфере нутрициологии",
      "Медийная личность",
      "Практикующий нутрициолог",
      "Блогер",
    ],
    social: {
      telegram: "https://t.me/pro_appetit",
      instagram: "https://www.instagram.com/pro_appetit",
    },
  },
  {
    time: "12:00 - 13:00",
    speakerName: "Евгения Меглинская",
    description: "Психолог",
    value: "meglinskaya",
    title: "РПП и правда о желании похудеть",
    small: "assets/speakers/meglinskaya/meglinskaya-small.jpg",
    cover: "assets/speakers/meglinskaya/meglinskaya.jpg",
    texts: [
      "Магистр Манчестерского Университета (University of Manchester), Великобритания",
      "Автор программ по коррекции пищевого поведения",
      "Автор книг \"Стоп срывам и перееданиям\" и \"Здоровый похудизм\"",
      "Приглашенный эксперт для телеканалов \"Мама-ТВ\", \"Домашний\", \"Здоровое ТВ\"",
      "Руководитель Школы Консультантов по психологической коррекции пищевого поведения",
    ],
    social: {
      telegram: "https://t.me/Meglinskaya",
      instagram: "https://www.instagram.com/pro_appetit",
      custom: "https://school.mylightlife.ru/",
    },
  },
  {
    time: "13:00 - 14:00",
    speakerName: "Игорь Молот",
    description: "Магистр по физической культуре и спорту",
    value: "molot",
    title:
      "Бег за чужим рационом. Скрытые угрозы копирования диеты спортсменов. ",
    small: "assets/speakers/molot/molot-small.jpg",
    cover: "assets/speakers/molot/molot.jpg",
    texts: [
      "Автор научных статей, 3-х книг о фитнесе и питании",
      "Победитель различных соревнований по силовым видам спорта",
      "Основатель проекта \"Спортивные советы\"",
    ],
    social: {
      vk: "https://vk.com/ig_molot",
      telegram: "t.me/sportivnyesovety",
      instagram: "www.instagram.com/ig_molot",
      youtube: "www.youtube.com/c/Спортивныесоветы",
    },
  },
  {
    time: "14:00 - 15:00",
    speakerName: "Александра Франц",
    description: "Химик, популяризатор науки",
    value: "franz",
    title: "Химия лжи: Правда о бытовых средствах",
    small: "assets/speakers/franz/franz-small.jpg",
    cover: "assets/speakers/franz/franz.jpg",
    texts: [
      "Высшее образование учителя химии и биологии",
      "Химик, популяризатор науки",
      "Спикер на радио и телевидении",
    ],
    social: {
      telegram: "https://t.me/chemistry_frants",
      instagram:
        "https://www.instagram.com/frants_alexandra?igsh=MWJkdm15azVwOTFoMA%3D%3D&utm_source=qr",
      youtube: "https://youtube.com/@frants_chemistry?si=-6RPnmdn-jHSjLYk",
      researchGate: "https://www.researchgate.net/profile/Tatiana-Golubeva-2/",
    },
  },
  {
    time: "15:00 - 16:00",
    speakerName: "Валерия Федоровских",
    description: "Врач-педиатр, нутрициолог",
    value: "fedorovskih",
    title: "Шаг к коллагену: Эффект или пустая надежда?",
    small: "assets/speakers/fedorovskih/fedorovskih-small.jpg",
    cover: "assets/speakers/fedorovskih/fedorovskih.jpg",
    texts: [
      "Закончила педиатрический факультет ТюмГМУ с отличием",
      "Закончила ординатуру по специальности \"Педиатрия\"",
      "Преподаватель биологической химии студентам медицинских ВУЗов",
    ],
    social: {
      telegram: "https://t.me/valerunaa",
      instagram: "https://www.instagram.com/valeruna_?igsh=aHFwcmZnbDExODhs",
    },
  },
  {
    time: "16:00 - 17:00",
    speakerName: "Владимир Нечепорук",
    description: "Врач-терапевт, брендмейкер врачей",
    value: "necheporuk",
    title: "Лайк за науку: Переигрываем лженауку её же правилами",
    small: "assets/speakers/necheporuk/necheporuk-small.jpg",
    cover: "assets/speakers/necheporuk/necheporuk.jpg",
    texts: [
      "Разработал и первым в РФ аккредитовал курсы по медблогингу",
      "\"ИнстаТерапия\", 2021г (+144 балла НМО)",
      "\"TikTok и Reels для докторов\", 2022г (+72 балла НМО)",
      "\"Ютубоскопия\", 2023г (+72 балла НМО)",
      "Автор первой в России книги про врачей-блогеров \"Медицинский блогинг в РФ\"",
    ],
    social: {
      telegram: "https://t.me/readydoctor",
      instagram: "https://www.instagram.com/readydoc/",
    },
  },
]

export const room2 = [
  {
    time: "11:00 - 12:00",
    speakerName: "Анна Берекетова",
    description: "Специалист по работе с питанием и пищевым поведением",
    value: "bereketova",
    title: "Ожирение бесконечности. Лабиринт рецидива",
    small: "assets/speakers/bereketova/bereketova-small.jpg",
    cover: "assets/speakers/bereketova/bereketova.jpg",
    texts: [
      "Методист и куратор образовательных курсов в сфере фитнеса",
      "Супервизионное сопровождение и наставничество специалистов в сфере фитнеса и питания",
      "Участник конференций и круглых столов в области здоровья",
      "Организатор воркшопов для корпоративных клиентов",
      "Автор руководства по модификации питания",
    ],
    social: {
      vk: "https://vk.com/anutaber",
      telegram: "https://t.me/nutaoede",
      instagram: "https://instagram.com/anutaber_nutri_s",
    },
  },
  {
    time: "12:00 - 13:00",
    speakerName: "Алексей Водовозов",
    description: "Врач токсиколог, научный журналист",
    value: "vodovozov",
    title: "Война двух миров: БАДы против лекарств",
    small: "assets/speakers/vodovozov/vodovozov-small.jpg",
    cover: "assets/speakers/vodovozov/vodovozov.jpg",
    texts: [
      "Научный журналист, автор книги \"Пациент разумный\"",
      "Член Ассоциации медицинских журналистов",
      "Призёр премии \"Russian Sci&Tech Writer of the Year\" (2019)",
      "Финалист премии \"Радиомания\" (2023)",
    ],
    social: {
      telegram: "https://t.me/dr_voenvrach",
    },
  },
  {
    time: "13:00 - 14:00",
    speakerName: "Амина Мирсакиева",
    description: "Физик, химик, научный популяризатор",
    value: "mirsakieva",
    title: "Пластик в масках: Мифы о био и обычном пластиках",
    small: "assets/speakers/mirsakieva/mirsakieva-small.jpg",
    cover: "assets/speakers/mirsakieva/mirsakieva.jpg",
    texts: [
      "Автор и ведущая подкаста \"Белка и Стрелка\"",
      "Магистр и бакалавр химического инжиниринга (КБТУ, Казахстан)",
      "PhD Material Physics (КТН, Швеция, 2017)",
      "PhD в материальной физике, ученая в немецкой индустрии",
      "100 новых лиц Казахстана 2021",
      "Основательница сообщества женщин-ученых из Казахстана",
    ],
    social: {
      telegram: "https://t.me/belkastrelkapodcast",
      instagram: "https://www.instagram.com/dr_amina_mir",
      custom: "https://belkastrelkapodcast.mave.digital",
    },
  },
  {
    time: "14:00 - 15:00",
    speakerName: "Татьяна Денисова",
    description: "Врач-эндокринолог, диетолог, клинический психолог",
    value: "denisova",
    title: "Голод и эмоции. Как взять аппетит под контроль",
    small: "assets/speakers/denisova/denisova-small.jpg",
    cover: "assets/speakers/denisova/denisova.jpg",
    texts: [
      "Главный диетолог сети клиника Фомина",
      "Сооснователь образовательного проекта \"Дневник Здоровья\" клиники Фомина для пациентов по работе с питанием и пищевым поведением",
      "Окончила Первый Московский государственный Медицинский университет им. И. М. Сеченова  по специальности лечебное дело",
      "Проходила обучение в клинической ординатуре по специальности Эндокринология в Эндокринологическом Научном центре",
      "Профессиональная переподготовка по диетологии (РУДН) и клинической психологии( Институт Организационной психологии)",
      "Специализация на диетологическом консультировании пациентов с метаболическими нарушениями, избыточной массой тела, ожирением,  РПП, девушек/женщин с ФГА на фоне спорта, диет и стресса",
      "Спикер образовательной онлайн-платформы Meducation. Обучающие лекции,  вебинары для врачей, пациентов,  посвящённые эндокринным нарушениям, а также питанию в норме и  при различных патологиях",
      "Стаж работы более 13 лет",
    ],
    social: {
      telegram: "https://t.me/doctor_denisova",
      instagram: "https://www.instagram.com/doctor_denisova_",
    },
  },
  {
    time: "15:00 - 16:00",
    speakerName: "Ольга Гавриленко",
    description: "Судебный юрист, юрисконсульт",
    value: "gavrilenko",
    title: "Нутрициолог в законе. Преступление и наказание",
    small: "assets/speakers/gavrilenko/gavrilenko-small.jpg",
    cover: "assets/speakers/gavrilenko/gavrilenko.jpg",
    texts: [
      "Судебный юрист по семейному и гражданскому праву",
      "Юрисконсульт по договорному праву",
    ],
    social: {
      phone: "",
      instagram: "https://www.instagram.com/gavrilenko__olga",
    },
  },
  {
    time: "16:00 - 17:00",
    speakerName: "Ольга Косникова",
    description:
      "Пищевой технолог",
    value: "kosnikova",
    title: "Эхо химии. Все правда о красителях и ароматизаторах",
    small: "assets/speakers/kosnikova/kosnikova-small.jpg",
    cover: "assets/speakers/kosnikova/kosnikova.jpg",
    texts: [
      "Пищевой технолог",
      (
        <Text>
          Автор блога{" "}
          <Anchor target="_blank" href="https://t.me/chemistry_by_olga">@chemistry_by_olga</Anchor>
        </Text>
      ),
      "Автор книги \"Страшная химия: Еда с Е-шками\"",
    ],
    social: {
      telegram: "https://t.me/chemistrybyolga",
      instagram: "https://www.instagram.com/chemistry_by_olga",
      vk: "https://www.vk.com/chemistry_by_olga",
      youtube: "https://youtube.com/@chemistry_by_olga",
      custom: "https://www.chitai-gorod.ru/r/UAbXZ",
    },
  },
]
