import React from "react"
import { Container, Title, SimpleGrid, Center, createStyles, Text } from "@mantine/core"
import { IconStars, IconTargetArrow, IconParachute } from "@tabler/icons-react"
import { primaryColor } from "../../constants"

const useStyles = createStyles((theme) => ({
  title: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 80,
    position: "relative",
    paddingBottom: 20,

    "&::before": {
      content: "\"\"",
      position: "absolute",
      display: "block",
      width: 90,
      height: 5,
      background: theme.colors[primaryColor][9],
      bottom: 0,
      left: "calc(50% - 45px)",
    },
  },

  feature: {
    position: "relative",
    paddingTop: theme.spacing.xl,
    paddingLeft: theme.spacing.xl,
  },

  overlay: {
    position: "absolute",
    height: 135,
    width: 180,
    top: 0,
    left: 0,
    backgroundColor: theme.colors[primaryColor][0],
    zIndex: 1,
    borderRadius: theme.radius.lg,
  },

  icon: {
    color: theme.colors[primaryColor][9],
  },

  content: {
    position: "relative",
    zIndex: 2,
  },

  cardTitle: {
    color: "#122c0f",
  },
}))

const data = [
  {
    text: "Целый день подтверждений теории и практики. Больше нет необходимости в гадании на кофейной гуще",
    title: "Научный десант",
    Icon: IconParachute,
  },
  {
    text: "Целостный и структурированный подход. Рассказываем простым языком о сложных вещах",
    title: "Прямо в яблочко",
    Icon: IconTargetArrow,
  },
  {
    text: "12 экспертов в сфере питания и медицины поделятся своим опытом. Используйте их знания в свою пользу",
    title: "Звёздная команда",
    Icon: IconStars,
  },
]

export function Features(): React.JSX.Element {
  const { classes } = useStyles()

  return (
    <Container id="features" size={1320} mt={60} mb={60}>
      <Center className={classes.title}>
        <Title color="#122c0f" order={2} align="center" size={32}>Фишки мероприятия</Title>
        <Text align="center" mt="sm" c="dimmed">В чём наша сила?</Text>
      </Center>
      <SimpleGrid
        cols={3}
        spacing={50}
        breakpoints={[
          { maxWidth: "lg", cols: 3 },
          { maxWidth: "md", cols: 2 },
          { maxWidth: "sm", cols: 1 },
        ]}
      >
        {data.map(({ Icon, title, text }, index) => (
          <div key={index} className={classes.feature}>
            <div className={classes.overlay}/>
            <div className={classes.content}>
              <Icon
                style={{ width: 50, height: 50 }}
                className={classes.icon}
                stroke={1.5}
              />
              <Text fw={700} fz={24} mb="xs" mt={5} className={classes.cardTitle}>
                {title}
              </Text>
              <Text mt="xl">
                {text}
              </Text>
            </div>
          </div>
        ))}
      </SimpleGrid>
    </Container>
  )
}
