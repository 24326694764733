import { CountryCode } from "libphonenumber-js"

export type CountryPhone = {
  code: CountryCode,
  label: string,
  phone: string,
}

export const countries: Array<CountryPhone> = [
  { code: "RU", label: "Российская Федерация", phone: "7" },
  { code: "BY", label: "Республика Беларусь", phone: "375" },
  { code: "UA", label: "Украина", phone: "380" },

  { code: "AU", label: "Австралия", phone: "61" },
  { code: "AT", label: "Австрия", phone: "43" },
  { code: "AZ", label: "Азербайджан", phone: "994" },
  { code: "AI", label: "Ангилья", phone: "1-264" },
  { code: "AR", label: "Аргентина", phone: "54" },
  { code: "AM", label: "Армения", phone: "374" },
  { code: "BE", label: "Бельгия", phone: "32" },
  { code: "BG", label: "Болгария", phone: "359" },
  { code: "BR", label: "Бразилия", phone: "55" },
  { code: "GB", label: "Великобритания", phone: "44" },
  { code: "HU", label: "Венгрия", phone: "36" },
  { code: "VN", label: "Вьетнам", phone: "84" },
  { code: "DE", label: "Германия", phone: "49" },
  { code: "GL", label: "Гренландия", phone: "299" },
  { code: "GR", label: "Греция", phone: "30" },
  { code: "GE", label: "Грузия", phone: "995" },
  { code: "DK", label: "Дания", phone: "45" },
  { code: "EG", label: "Египет", phone: "20" },
  { code: "IL", label: "Израиль", phone: "972" },
  { code: "IN", label: "Индия", phone: "91" },
  { code: "ID", label: "Индонезия", phone: "62" },
  { code: "IE", label: "Ирландия", phone: "353" },
  { code: "ES", label: "Испания", phone: "34" },
  { code: "IT", label: "Италия", phone: "39" },
  { code: "KZ", label: "Казахстан", phone: "7" },
  { code: "CM", label: "Камерун", phone: "237" },
  { code: "CA", label: "Канада", phone: "1" },
  { code: "QA", label: "Катар", phone: "974" },
  { code: "CY", label: "Кипр", phone: "357" },
  { code: "CN", label: "Китай", phone: "86" },
  { code: "CO", label: "Колумбия", phone: "57" },
  { code: "CU", label: "Куба", phone: "53" },
  { code: "KG", label: "Кыргызстан", phone: "996" },
  { code: "LV", label: "Латвия", phone: "371" },
  { code: "LT", label: "Литва", phone: "370" },
  { code: "LI", label: "Лихтенштейн", phone: "423" },
  { code: "LU", label: "Люксембург", phone: "352" },
  { code: "MV", label: "Мальдивы", phone: "960" },
  { code: "MX", label: "Мексика", phone: "52" },
  { code: "MD", label: "Молдова", phone: "373" },
  { code: "MN", label: "Монголия", phone: "976" },
  { code: "NL", label: "Нидерланды", phone: "31" },
  { code: "NZ", label: "Новая Зеландия", phone: "64" },
  { code: "NO", label: "Норвегия", phone: "47" },
  { code: "AE", label: "Объединенные Арабские Эмираты", phone: "971" },
  { code: "PL", label: "Польша", phone: "48" },
  { code: "PT", label: "Португалия", phone: "351" },
  { code: "RO", label: "Румыния", phone: "40" },
  { code: "US", label: "Соединенные Штаты", phone: "1" },
  { code: "SA", label: "Саудовская Аравия", phone: "966" },
  { code: "RS", label: "Сербия", phone: "381" },
  { code: "SG", label: "Сингапур", phone: "65" },
  { code: "SK", label: "Словакия", phone: "421" },
  { code: "SI", label: "Словения", phone: "386" },
  { code: "TH", label: "Таиланд", phone: "66" },
  { code: "TJ", label: "Таджикистан", phone: "992" },
  { code: "TR", label: "Турция", phone: "90" },
  { code: "TM", label: "Туркменистан", phone: "993" },
  { code: "UZ", label: "Узбекистан", phone: "998" },
  { code: "PH", label: "Филиппины", phone: "63" },
  { code: "FI", label: "Финляндия", phone: "358" },
  { code: "FR", label: "Франция", phone: "33" },
  { code: "HR", label: "Хорватия", phone: "385" },
  { code: "CZ", label: "Чехия", phone: "420" },
  { code: "CL", label: "Чили", phone: "56" },
  { code: "ME", label: "Черногория", phone: "382" },
  { code: "CH", label: "Швейцария", phone: "41" },
  { code: "SE", label: "Швеция", phone: "46" },
  { code: "LK", label: "Шри-Ланка", phone: "94" },
  { code: "EE", label: "Эстония", phone: "372" },
  { code: "IS", label: "Исландия", phone: "354" },
  { code: "JP", label: "Япония", phone: "81" },
]
