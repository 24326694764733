import { makeAutoObservable } from "mobx";

class FormModalStoreImpl {
  data: {
    target: string | null;
    tag_ids?: Array<number>;
  } = {
    target: null,
  };
  isOpened: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  setData(data: {
    target: string | null;
    tag_ids?: Array<number>;
  }) {
    this.data = data;

    if (data.target) {
      this.isOpened = true;
    }
  }

  setIsOpened(state: boolean) {
    this.isOpened = state;

    if (!state) {
      this.setData({
        target: null,
        tag_ids: undefined,
      });
    }
  }
}

export const FormModalStore = new FormModalStoreImpl();
