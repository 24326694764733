import * as Yup from "yup"
import { AsYouType, getExampleNumber, parsePhoneNumberFromString } from "libphonenumber-js"
import examples from "libphonenumber-js/examples.mobile.json"
import { CountryPhone } from "./countries"

export function createPhoneMask(phone: string, maskTemplate: string): string {
  const parts = maskTemplate.split(" ")

  if (parts.length === 0) {
    return ""
  }

  const escapedCode = phone.split("").map(d => `\\${d}`).join("")
  const countryPart = `+${escapedCode}`

  const zeroParts = parts
    .slice(1)
    .map(part => part.replace(/x/g, "0"))

  return [ countryPart, ...zeroParts ].join(" ")
}

export const formSchema = Yup.object().shape({
  name: Yup.string().required("Имя обязательно"),
  phone: Yup.string()
    .required("Телефон обязателен")
    .test("phone", "Неверный номер телефона", function (value, context) {
      const { countryPhone } = context.parent
      if (!countryPhone || !value) return false

      try {
        const phoneNumber = parsePhoneNumberFromString(value, countryPhone.code)
        return phoneNumber?.isValid() || false
      } catch {
        return false
      }
    }),
})

export function getPhoneMaskData(countryPhone: CountryPhone): [ string, string ] {
  const asYouType = new AsYouType(countryPhone.code)

  const phoneNumber = getExampleNumber(countryPhone.code, examples)
  const escapedCode = countryPhone.phone.split("").map(d => `\\${d}`).join("")

  const value = phoneNumber !== undefined
    ? phoneNumber.formatInternational()
    : `+${escapedCode} 000 000 00 00`

  asYouType.input(value)
  const phoneMask = createPhoneMask(countryPhone.phone, asYouType.getTemplate())
  const phonePlaceholder = phoneMask.replace(/\\/g, "")

  return [ phoneMask, phonePlaceholder ]
}
