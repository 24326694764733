import React from "react"
import {
  createStyles,
  Title,
  Flex,
  Box,
  Text,
  Modal,
  ScrollArea,
  List,
  ActionIcon, Anchor,
} from "@mantine/core"
import { Image } from "../../shared/ui"
import {
  IconBrandInstagram,
  IconBrandTelegram,
  IconBrandTiktok,
  IconBrandVk,
  IconBrandYoutube,
  IconBroadcast,
  IconLetterR,
  IconPoint,
} from "@tabler/icons-react"
import { useMediaQuery } from "@mantine/hooks"
import { observer } from "mobx-react-lite"
import { SpeakerStore } from "../../app/SpeakerStore"
import { colorWithoutMantine } from "../../constants"

const useStyles = createStyles((theme) => ({
  modalContent: {
    width: "100%",
    maxWidth: 1024,

    [theme.fn.smallerThan(1024)]: {
      maxWidth: 480,
    },
  },

  modalContentFlex: {
    flexGrow: 1,
    height: "100%",

    [theme.fn.smallerThan(1024)]: {
      flexDirection: "column",
    },
  },

  modalContentTextBox: {
    display: "flex",
    flexDirection: "column",
    padding: 48,
    width: "50%",

    [theme.fn.smallerThan(1024)]: {
      width: "100%",
    },

    [theme.fn.smallerThan(768)]: {
      padding: 32,
      paddingLeft: 24,
      paddingRight: 24,
    },

    [theme.fn.smallerThan(480)]: {
      padding: 24,
      paddingLeft: 12,
      paddingRight: 12,
    },
  },

  modalContentTitle: {
    marginBottom: 12,
    fontSize: 32,

    [theme.fn.smallerThan(480)]: {
      textAlign: "center",
      fontSize: 24,
    },
  },

  modalContentDesc: {
    marginBottom: 28,
    fontSize: 22,

    [theme.fn.smallerThan(480)]: {
      textAlign: "center",
      fontSize: 20,
    },
  },

  modalCloseBtn: {
    position: "absolute",
    top: 16,
    right: 16,
    zIndex: 1,
  },

  socialIcon: {
    transition: "all 0.2s easy-in-out",
    opacity: 0.8,

    "&:hover": {
      opacity: 1,
    },
  },

  socialWrapper: {
    [theme.fn.smallerThan(480)]: {
      justifyContent: "center",
    },
  },
}))

export const SpeakerModal = observer(function SpeakerModal(): React.JSX.Element {
    const { classes } = useStyles()
    const { cover = "", description, speakerName = "Спикер", texts = [], social } = SpeakerStore.speaker || {}

    const close = () => SpeakerStore.setIsOpened(false)

    const isTablet = useMediaQuery(`(max-width: 1024px)`)
    const isMobile = useMediaQuery(`(max-width: 480px)`)

    return (
      <Modal.Root scrollAreaComponent={ScrollArea.Autosize} zIndex={2000} size="auto" centered
                  opened={SpeakerStore.isOpened} onClose={close}>
        <Modal.Overlay/>
        <Modal.Content radius="lg" className={classes.modalContent}>
          <Modal.CloseButton variant="light" size="xl" radius="lg" className={classes.modalCloseBtn}/>
          <Modal.Body p={0}>
            <Flex className={classes.modalContentFlex}>
              <Image
                src={cover}
                ratio={isTablet ? "3/4" : "384/576"}
                sx={{
                  width: isTablet ? "100%" : "50%",
                  backgroundPosition: "center",
                }}
              />
              <Box className={classes.modalContentTextBox}>
                <Title className={classes.modalContentTitle} order={1}>{speakerName}</Title>
                <Text c="dimmed" className={classes.modalContentDesc}>{description}</Text>

                <List
                  spacing="md"
                  size={isMobile ? "md" : "lg"}
                  icon={<IconPoint size={isMobile ? "1rem" : "1.5rem"} color={colorWithoutMantine}/>}
                >
                  {texts.map((el, index) => (
                    <List.Item key={index}>
                      {el}
                    </List.Item>
                  ))}
                </List>

                {social && (
                  <Flex className={classes.socialWrapper} mt="auto" pt={24} wrap="wrap" align="center" justify="flex-end"
                        gap={8}>
                    {social.telegram && (
                      <ActionIcon
                        className={classes.socialIcon}
                        component="a"
                        href={social.telegram}
                        target="_blank"
                        variant="gradient"
                        gradient={{ from: "indigo.5", to: "blue.5" }}
                        size={36}
                        radius="lg"
                      >
                        <IconBrandTelegram/>
                      </ActionIcon>
                    )}
                    {social.instagram && (
                      <ActionIcon
                        className={classes.socialIcon}
                        component="a"
                        href={social.instagram}
                        target="_blank"
                        variant="gradient"
                        gradient={{ from: "grape", to: "pink" }}
                        size={36}
                        radius="lg"
                      >
                        <IconBrandInstagram/>
                      </ActionIcon>
                    )}
                    {social.vk && (
                      <ActionIcon
                        className={classes.socialIcon}
                        component="a"
                        href={social.vk}
                        target="_blank"
                        color="blue"
                        variant="filled"
                        size={36}
                        radius="lg"
                      >
                        <IconBrandVk/>
                      </ActionIcon>
                    )}
                    {social.youtube && (
                      <ActionIcon
                        className={classes.socialIcon}
                        component="a"
                        href={social.youtube}
                        target="_blank"
                        color="red"
                        variant="filled"
                        size={36}
                        radius="lg"
                      >
                        <IconBrandYoutube/>
                      </ActionIcon>
                    )}
                    {social.researchGate && (
                      <ActionIcon
                        className={classes.socialIcon}
                        component="a"
                        href={social.researchGate}
                        target="_blank"
                        color="cyan"
                        variant="filled"
                        size={36}
                        radius="lg"
                      >
                        <IconLetterR/>
                      </ActionIcon>
                    )}
                    {social.tiktok && (
                      <ActionIcon
                        className={classes.socialIcon}
                        component="a"
                        href={social.tiktok}
                        target="_blank"
                        color="dark"
                        variant="filled"
                        size={36}
                        radius="lg"
                      >
                        <IconBrandTiktok/>
                      </ActionIcon>
                    )}
                    {social.custom && (
                      <ActionIcon
                        className={classes.socialIcon}
                        component="a"
                        href={social.custom}
                        target="_blank"
                        color="orange"
                        variant="filled"
                        size={36}
                        radius="lg"
                      >
                        <IconBroadcast/>
                      </ActionIcon>
                    )}
                  </Flex>
                )}

              </Box>
            </Flex>
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
    )
  },
)

