import {
  Center,
  Container,
  createStyles,
  Title,
  Text,
  Input,
  Button,
  Alert,
  TextInput,
  Stack,
  Textarea,
  Box, Grid,
} from "@mantine/core"
import React, { useEffect, useMemo, useState } from "react"
import { observer } from "mobx-react-lite"
import { IconAlertCircle, IconCircleCheck } from "@tabler/icons-react"
import { IMaskInput } from "react-imask"
import { useForm, yupResolver } from "@mantine/form"
import { type AxiosError } from "axios"
import {
  AmoRequest,
  CitadelApiError,
  sendRequestToAMO,
} from "../../shared/api/amo"
import { useMediaQuery } from "@mantine/hooks"
import { primaryColor, secondaryColor } from "../../constants"
import { CountrySelector, formSchema, getPhoneMaskData } from "../country-selector"
import { countries, CountryPhone } from "../country-selector/countries"

const useStyles = createStyles((theme) => ({
  wrapper: {
    paddingTop: 60,
    paddingBottom: 60,
    width: "100%",

    backgroundImage: "url(assets/about-bg.jpg)",
    backgroundAttachment: "fixed",
    backgroundPosition: "top center",
    backgroundSize: "cover",
    overflow: "hidden",
    position: "relative",
    zIndex: 0,

    "&::before": {
      content: "''",
      backgroundColor: "rgb(255,255,255, 0.9)",
      position: "absolute",
      bottom: 0,
      top: 0,
      left: 0,
      right: 0,
      zIndex: -1,
    },
  },

  title: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 48,
    position: "relative",
    paddingBottom: 20,

    "&::before": {
      content: "\"\"",
      position: "absolute",
      display: "block",
      width: 90,
      height: 5,
      background: theme.colors[primaryColor][8],
      bottom: 0,
      left: "calc(50% - 45px)",
    },
  },

  box: {
    zIndex: 2,
  },
}))

export const Form = observer(function Form() {
  const { classes } = useStyles()
  const matched = useMediaQuery(`(max-width: 768px)`)
  const is425 = useMediaQuery(`(max-width: 425px)`)

  const [ isLoading, setIsLoading ] = useState<boolean>(false)
  const [ isSuccess, setIsSuccess ] = useState<boolean>(false)
  const [ error, setError ] = useState<string | null>(null)

  const form = useForm({
    validateInputOnChange: true,
    validate: yupResolver(formSchema),
    initialValues: {
      name: "",
      phone: "",
      countryPhone: countries[0],
      message: "",
    },
  })

  const [ phoneMask, phonePlaceholder ] = useMemo(() => {
    const countryPhone = form.values.countryPhone
    return getPhoneMaskData(countryPhone)
  }, [ form.values.countryPhone.code ])

  const onSubmit = (values: typeof form.values): void => {
    setIsLoading(true)
    setIsSuccess(false)
    setError(null)

    const body: AmoRequest = {
      contact: {
        name: values.name,
        phone: values.phone.replace(/[\s()-]/g, ""),
      },
      name: "Конференция по нутрициологии. Форма внизу у футера",
      message: values.message,
    }

    void sendRequestToAMO(body)
      .then(() => {
        setIsSuccess(true)
      })
      .catch((err: AxiosError<CitadelApiError>) => {
        if (err.response) {
          setError(err.response.data.errors[0].detail)
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    return () => {
      form.reset()
      setIsLoading(false)
      setIsSuccess(false)
      setError(null)
    }
  }, [])

  return (
    <section id="askQuestion" className={classes.wrapper}>
      <Container size={1320}>
        <Center className={classes.title}>
          <Title color="#122c0f" order={2} align="center" size={32}>
            Форма обратной связи
          </Title>
          <Text align="center" mt="sm" color="#122c0f">
            Работаем без перерывов и выходных
          </Text>
        </Center>

        <Box className={classes.box}>
          {isSuccess ? (
            <Container size={560}>
              <Alert
                styles={{ title: { fontSize: 18 }, message: { fontSize: 16 } }}
                icon={<IconCircleCheck/>}
                title="Заявка успешно отправлена!"
                color={secondaryColor}
                radius="lg"
              >
                В ближайшее время с Вами свяжется наш сотрудник и ответит на все
                Ваши вопросы.
              </Alert>
            </Container>
          ) : (
            <Container size={560}>
              <form onSubmit={form.onSubmit(onSubmit)}>
                <Stack spacing={is425 ? "md" : "xl"}>
                  <TextInput
                    disabled={isLoading}
                    placeholder="Имя"
                    radius="lg"
                    size={matched ? "md" : "lg"}
                    {...form.getInputProps("name")}
                  />

                  <Grid gutter={is425 ? "md" : "lg"}>
                    <Grid.Col xs={12} sm={5} lg={6}>
                      <CountrySelector
                        disabled={isLoading}
                        value={form.values.countryPhone}
                        onChange={(countryPhone: CountryPhone) => {
                          form.setFieldValue("countryPhone", countryPhone)
                          Promise.resolve()
                            .then(() => form.setFieldValue("phone", ""))
                            .then(() => form.clearFieldError("phone"))
                        }}
                      />
                    </Grid.Col>
                    <Grid.Col span="auto">
                      <Input.Wrapper
                        id="participant-form-phone"
                        error={form.errors["phone"]}
                        size={matched ? "md" : "lg"}
                      >
                        <Input
                          disabled={isLoading}
                          component={IMaskInput}
                          mask={phoneMask}
                          id="participant-form-phone"
                          placeholder={phonePlaceholder}
                          radius="lg"
                          size={matched ? "md" : "lg"}
                          {...form.getInputProps("phone")}
                        />
                      </Input.Wrapper>
                    </Grid.Col>
                  </Grid>

                  <Textarea
                    autosize
                    minRows={3}
                    maxRows={15}
                    disabled={isLoading}
                    {...form.getInputProps("message")}
                    placeholder="Ваше сообщение..."
                    radius="lg"
                    size={matched ? "md" : "lg"}
                  />
                  <Button
                    loading={isLoading}
                    type="submit"
                    fullWidth
                    variant="gradient"
                    gradient={{
                      from: `${primaryColor}.9`,
                      to: `${secondaryColor}.8`,
                    }}
                    radius="lg"
                    size={matched ? "md" : "lg"}
                  >
                    Отправить
                  </Button>

                  {error && (
                    <Alert
                      mt="xl"
                      icon={<IconAlertCircle/>}
                      title="Ошибка отправки данных"
                      color="red"
                      radius="lg"
                    >
                      {error}
                    </Alert>
                  )}
                </Stack>
              </form>
            </Container>
          )}
        </Box>
      </Container>
    </section>
  )
})
